<template>
  <div>
    <loading :active="isLoading" :is-full-page="true"></loading>
    <div class="center">
      <Popper>
        <i style="color: #1f7aaf" class="material-icons md-48"> info </i>
        <template #content>
          <div
            style="min-width: 350px"
            class="alert alert-secondary"
            role="alert"
          >
            <div
              class="info-text"
              style="overflow: auto; max-height: 300px"
              v-html="mapDescription"
            ></div>
          </div>
        </template>
      </Popper>
    </div>
    <div class="text-center" id="confirm-map-position-div">
      <div id="map" class="map" style="position: "></div>
    </div>
  </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import NavHeader from "../components/navbars/NavHeader.vue";
import leaflet from "leaflet";
import proj4 from "proj4";
import Popper from "vue3-popper";
import axios from "axios";
import { toRaw } from "vue";
// Import component
import Loading from "vue3-loading-overlay";
// Import stylesheet
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

import atlas32Squares from "@/assets/json/atlas_squares/atlas_squares_utm_32.json";
import atlas33Squares from "@/assets/json/atlas_squares/atlas_squares_utm_33.json";

delete leaflet.Icon.Default.prototype._getIconUrl;
leaflet.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});


export default {
  components: {
    NavHeader,
    Popper,
    Loading,
  },
  data() {
    return {
      title: this.$englishPreferred ? "Coverage map" : "Dækningskort",
      map: null,
      atlasSquares: [ ...atlas32Squares, ...atlas33Squares ], // uses atlas32Squares import instead
      atlasSquarePoints: [],
      countPoints: [],
      isMapColorFilled: true,
      isMapShowingPoints: false,
      baseLayers: null,
      lastZoom: 7,
      gpsMarker: null,
      fillOpacity: 0.4,
      mapDescription: this.$englishPreferred
        ? "On this map Denmark is separated into squares.<br><br>" +
          "The color fill of the squares are based on how attractive it is currently to make a new count.<br>" +
          "Zoom in on the map to see points and info about the squares.<br><br>" +
          "Amount of counts and points for squares are calculated nigthly."
        : "Dækningskort med spontantællinger i hvert 5x5 km kvadrat.<br><br>" +
          "Er der foretaget mindst en tælling siden i går i kvadratet, vises det med rødt.<br>" +
          "Grønne kvadrater er ikke optalt de seneste 30 dage.<br><br>" +
          "Spontantællinger kan foretages på alle steder og tidspunkter af året og døgnet. " +
          "Man står stille i fem minutter og indtaster alt, hvad man ser og hører af fugle.",
      ortoPreferred: false,
      isLoading: true,
    };
  },
  methods: {
    createMap() {
      this.map = leaflet.map("map", {zoomSnap: 0.1}).setView([56.18, 11.5], 6.4);
      let openStreetMap = leaflet.tileLayer(
        "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        {
          attribution:
            '<small><a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://www.openstreetmap.org/copyright">CC-BY-SA</a></small>',
          maxZoom: 18,
          id: "openstreetmap",
        }
      );

      let ortofotoLayer = leaflet.tileLayer.wms(
        "https://service.dofbasen.dk/geoserver/dof/wms",
        {
          layers: "dof:orto_foraar_wms",
          format: "image/png",
          attribution:
            '&copy; <a target="_blank" href="https://download.kortforsyningen.dk/content/vilk%C3%A5r-og-betingelser">Styrelsen for Dataforsyning og Effektivisering',
        }
      );

      let baseLayers = {
        "Datafordeler Ortofoto": ortofotoLayer,
        OpenStreetMap: openStreetMap,
      };

      // Add layer control to map
      this.baseLayers = leaflet.control
        .layers(baseLayers)
        .addTo(toRaw(this.map));

      leaflet.control.scale({ imperial: false }).addTo(toRaw(this.map));

      this.map.on("moveend", (e) => {
        this.mapMoveend();
      });

      this.map.on("click", (e) => {
        this.map.dragging.disable();
        this.map.doubleClickZoom.disable();
        this.map.scrollWheelZoom.disable();
        this.map.flyTo([e.latlng.lat, e.latlng.lng], 11);
        this.map.dragging.enable();
        this.map.doubleClickZoom.enable();
        this.map.scrollWheelZoom.enable();
      });

      if (this.ortoPreferred == true) {
        ortofotoLayer.addTo(toRaw(this.map));
      } else {
        openStreetMap.addTo(toRaw(this.map));
      }
    },
    mapMoveend() {
      var mapBounds = this.map.getBounds().pad(0.5);
      const zoom = this.map.getZoom();
      const displayTooltipZoomLevel = 11;
      const tooltipOpacity = this.isMapColorFilled ? 0.75 : 0.4;

      if (zoom >= displayTooltipZoomLevel) {
        this.atlasSquarePolygonGroup.eachLayer((layer) => {
          if (mapBounds.contains(layer.getLatLngs())) {
            // Check if square layer has tooltip already

            if (!layer.getTooltip()) {
              let tooltipText;
              if (this.isMapShowingPoints) {
                let point =
                  layer.atlasSquare.points != null
                    ? layer.atlasSquare.points
                    : 100;
                tooltipText = "<b>Point: " + point + "</b>";
              } else {
                if (
                  layer.atlasSquare.totalCounts != null &&
                  layer.atlasSquare.totalCounts > 0
                ) {
                  let latest30DaysCounts = 0;
                  if (
                    layer.atlasSquare.totalCountsLatest30Days != null &&
                    layer.atlasSquare.totalCountsLatest30Days > 0
                  ) {
                    latest30DaysCounts =
                      layer.atlasSquare.totalCountsLatest30Days;
                  }
                  tooltipText = this.$englishPreferred
                    ? "Counts in total:<br><b>" +
                      layer.atlasSquare.totalCounts +
                      "</b></br>30 days:<br><b>" +
                      latest30DaysCounts +
                      "</b>"
                    : "Tællinger i alt:<br><b>" +
                      layer.atlasSquare.totalCounts +
                      "</b></br>Seneste måned:<br><b>" +
                      latest30DaysCounts +
                      "</b>";
                } else {
                  tooltipText = this.$englishPreferred
                    ? "No counts"
                    : "Ingen tællinger";
                }
              }

              layer
                .bindTooltip(
                  "<div style='padding:1px 3px 1px 3px;color:white;background:#1f7aaf;'>" +
                    tooltipText +
                    "</div>",
                  {
                    className: "leaflet-tooltip-own",
                    direction: "center",
                    permanent: true,
                    opacity: tooltipOpacity,
                  }
                )
                .addTo(toRaw(this.map));
            }
          }
        });
      } else {
        this.atlasSquarePolygonGroup.eachLayer((layer) => {
          if (layer.getTooltip()) {
            layer.unbindTooltip();
          }
        });
      }
    },
    insertAtlasSquaresOnMap() {
      var fillOpacity = this.isMapColorFilled ? this.fillOpacity : 0.0;
      //UTM-32 EPSG: 25832
      this.atlasSquares.forEach((atlasSquare) => {
        var utm = "+proj=utm +zone=32";
        var wgs84 = "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs";
        var latlngs = [
          proj4(utm, wgs84, atlasSquare.geometry.coordinates[0][0]).reverse(),
          proj4(utm, wgs84, atlasSquare.geometry.coordinates[0][1]).reverse(),
          proj4(utm, wgs84, atlasSquare.geometry.coordinates[0][2]).reverse(),
          proj4(utm, wgs84, atlasSquare.geometry.coordinates[0][3]).reverse(),
          proj4(utm, wgs84, atlasSquare.geometry.coordinates[0][4]).reverse(),
        ];

        let color = atlasSquare.points
          ? this.getGreenToRed(atlasSquare.points)
          : this.getGreenToRed(100);
        if (atlasSquare.points != null && atlasSquare.points == 0) {
          color = "rgb(0,0,0)";
        }
        var polygon = leaflet.polygon(latlngs, {
          color: color,
          fillOpacity: fillOpacity,
          weight: 1,
        });
        polygon.atlasSquare = atlasSquare;
        this.atlasSquarePolygonGroup.addLayer(polygon);
      });
      this.atlasSquarePolygonGroup.addTo(toRaw(this.map));
      this.isLoading = false;
    },
    getGreenToRed(percent) {
      let r, g;
      r =
        percent < 50
          ? 255
          : Math.floor(255 - ((percent * 2 - 100) * 255) / 100);
      g = percent > 50 ? 255 : Math.floor((percent * 2 * 255) / 100);
      return "rgb(" + r + "," + g + ",0)";
    },
    toggleMapFill() {
      this.isMapColorFilled = !this.isMapColorFilled;
      if (!this.isMapColorFilled) {
        this.atlasSquarePolygonGroup.eachLayer((layer) => {
          let color = layer.atlasSquare.points
            ? this.getGreenToRed(layer.atlasSquare.points)
            : this.getGreenToRed(100);
          layer.setStyle({
            color: color,
            fillOpacity: 0.0,
          });
        });
      } else {
        this.atlasSquarePolygonGroup.eachLayer((layer) => {
          let color = layer.atlasSquare.points
            ? this.getGreenToRed(layer.atlasSquare.points)
            : this.getGreenToRed(100);
          layer.setStyle({
            color: color,
            fillOpacity: this.fillOpacity,
          });
        });
      }
      this.atlasSquarePolygonGroup.eachLayer((layer) => {
        if (layer.getTooltip()) {
          layer.unbindTooltip();
        }
      });
      this.mapMoveend();
    },

    async getAtlasSquarePoints() {
      await this.getAtlasSquarePointsFromAPI()
        .then((response) => {
          //atlasSquarePointRepository.clearAll();
          //atlasSquarePointRepository.insertAtlasSquarePoints(response.data);
          this.atlasSquarePoints = response.data;
          console.log("connecting atlas squares to points");
          this.connectAtlasSquarePointsToAtlasSquares();
          this.insertAtlasSquaresOnMap();
        })
        .catch((error) => {
          console.log("Server did not respond"); //("Server did not respond, retrieving from local");
          //this.getAtlasSquarePointsFromLocal();
        });
    },
    getAtlasSquarePointsFromAPI() {
      return axios.get("/spontan/atlas-squares/points");
    },
    connectAtlasSquarePointsToAtlasSquares() {
      //atlas32Squares.forEach((atlasSquare) => {
      this.atlasSquares.forEach((atlasSquare) => {
        var result = this.atlasSquarePoints.filter((atlasSquarePoint) => {
          return atlasSquarePoint.kvadratnr == atlasSquare.properties.kvadratnr;
        });
        if (result.length > 0) {
          if (atlasSquare.points == null) {
            atlasSquare.points = result[0].points;
          }
          atlasSquare.totalCounts = result[0].totalCounts;
          atlasSquare.totalCountsLatest30Days = result[0].totalCounts30Days;
        }
      });
    },
  },
  mounted() {
    //if ($cookies.isKey("ortoPreferred")) {
    //  this.ortoPreferred = true;
    //}
    this.atlasSquarePolygonGroup = leaflet.layerGroup();
    this.createMap();
    //this.getAtlasSquares();
    this.getAtlasSquarePoints();
  },
};
</script>

<style scoped>
.center {
  position: absolute !important;
  top: 100px !important;
  /*left: 90% !important;*/
  right: -13px;
  transform: translate(-50%, -50%) !important;
  -webkit-transform: translate(-50%, -50%) !important;
}
#map{
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0;
  position: absolute;
  z-index: -300;
}
.info-text{
  font-size: 0.72em;
}
</style>
